<template>
  <div id="errorLayout" class="asf-error-page">
    <AsfError :status-code="error.statusCode" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ErrorLayout',
  layout: 'empty',
  props: ['error'],
  setup() {
    const { reportPageView } = useTagManager()
    reportPageView('error')
  },
  head: {}
})
</script>
